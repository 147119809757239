import revive_payload_client_ZBCF3c1Vmy from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7vjBGNm4Cy from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tXrStZeWZG from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Pe6l2KtNyE from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_IO6jCDV9ZD from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Cr42t5pWs5 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bvjDsyR7wZ from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_CObH5mPwbF from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_ygEPFF54vE from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/allard-pierson/allard-pierson-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_ZPrdFrI3fd from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_DNQ9f0Uvbs from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.3_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_bhNROklQi6 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.3_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_e03Q6ml2Ih from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.14.1592_@parcel+wat_kjpntzl434ud477o7iyuah4jz4/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_8MYLadTneg from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.27.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_PgHH4iaCg3 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.27.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import formie_C6ipYBj42X from "/builds/allard-pierson/allard-pierson-frontend/layers/formie/plugins/formie.js";
import gtm_cXsUZobmSF from "/builds/allard-pierson/allard-pierson-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_ZBCF3c1Vmy,
  unhead_7vjBGNm4Cy,
  router_tXrStZeWZG,
  payload_client_Pe6l2KtNyE,
  navigation_repaint_client_IO6jCDV9ZD,
  check_outdated_build_client_Cr42t5pWs5,
  chunk_reload_client_bvjDsyR7wZ,
  cross_origin_prefetch_client_CObH5mPwbF,
  plugin_vue3_ygEPFF54vE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZPrdFrI3fd,
  switch_locale_path_ssr_DNQ9f0Uvbs,
  i18n_bhNROklQi6,
  plugin_e03Q6ml2Ih,
  plugin_8MYLadTneg,
  nuxt_plugin_PgHH4iaCg3,
  formie_C6ipYBj42X,
  gtm_cXsUZobmSF
]