<template>
    <section
        class="section"
        :data-width="sectionWidth"
    >
        <div class="section__container">
            <div
                v-if="!!$slots.title"
                class="section__header"
            >
                <h2
                    class="section__title"
                    :class="`section__title--${titleStyle}`"
                >
                    <slot name="title" />
                </h2>
            </div>

            <div class="section__body">
                <slot />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        sectionWidth: {
            type: String,
            default: 'page',
            validator(value) {
                return ['page', 'content', 'bleed'].includes(value);
            }
        },
        titleStyle: {
            type: String,
            default: 'primary',
            validator(value) {
                return ['primary', 'secondary'].includes(value);
            }
        }
    }
};
</script>

<style lang="less" src="./BaseSection.less" />
