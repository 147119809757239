<template>
    <slot name="FormieCheckbox">
        <div class="dn-form-field__label">
            <label>
                {{ $attrs?.['input-bindings']?.['data-label'] }}
            </label>
        </div>
        <div class="dn-form-field__description" />
        <BaseCheckbox v-bind="$attrs">
            <slot />
        </BaseCheckbox>
    </slot>
</template>
