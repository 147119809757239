import FormieForm from './components/FormieForm.vue';
import FormiePlugin from './FormiePlugin';

export const formieSymbol = Symbol('formie');

const Formie = {
    install(app, config = {}) {
        app.provide(formieSymbol, new FormiePlugin(app, config));
    }
};

// Exporting base component
export { FormieForm };

export default Formie;
