export const getField = (field = {}) => {
    const getInputBindings = (fieldVar = field) => {
        if (!fieldVar) {
            return {};
        }

        const renderLabel =
            toValue(field)?.descriptionHtml !== fieldVar?.label ||
            fieldVar.typeName !== 'Field_Agree';

        return {
            id: getId(fieldVar.handle),
            name: getName(fieldVar.handle),
            required: fieldVar.required,
            placeholder: fieldVar.placeholder,
            'data-label': renderLabel ? fieldVar.label : null,
            ...validationMessages(fieldVar, fieldVar.requiredErrorMessage),
            ...attributesTable(fieldVar.inputAttributes),
        };
    };

    const getFieldBindings = (fieldVar = field) => {
        if (!fieldVar) {
            return {};
        }

        return {
            class: {
                [fieldVar.cssClasses]: fieldVar.cssClasses,
                'formie-field--disabled': fieldVar.visibility === 'disabled',
                'formie-field--hidden': fieldVar.visibility === 'hidden',
            },
            ...attributesTable(fieldVar.containerAttributes),
        };
    };

    const getLabelBindings = (fieldVar = field) => {
        if (!fieldVar) {
            return {};
        }

        return {
            for: getId(fieldVar.handle),
            'data-label-position': getLabelPosition(fieldVar.labelPosition),
        };
    };

    const getDefaultValue = () => {
        if (field.typeName === 'Field_Number') {
            return Number(field.defaultValue) || 0;
        }

        if (field.typeName === 'Field_Checkboxes') {
            return defaultOptions(field.options);
        }

        if (field.typeName === 'Field_Dropdown') {
            return defaultOption(field.options).value;
        }

        if (field.typeName === 'Field_Radio') {
            return defaultOption(field.options).value;
        }

        if (field.typeName === 'Field_Date') {
            // TODO: support full multifield.
            return field.defaultDate;
        }

        // generic fallback clause for any other fields with nested row subfields.
        const flatFields = field?.nestedRows?.flatMap(row => row.fields) ?? [];
        if (flatFields?.length > 0) {
            return Object.fromEntries(flatFields.map(({ handle, defaultValue }) => {
                return [handle, defaultValue];
            }));
        }

        return field.defaultValue || '';
    };

    const defaultOption = (options = [], defaultValue = null) => {
        if (!options || options.length === 0) {
            return null;
        }

        if (defaultValue) {
            return options.find(option => option.value === defaultValue);
        }

        return options.find(option => option.isDefault) || options[0];
    };

    const defaultOptions = (options = []) => {
        if (!options || options.length === 0) {
            return [];
        }

        const optionsList = options.filter(option => option.isDefault);
        if (optionsList.length > 0) {
            return optionsList.map(option => option.value);
        }

        return [];
    };

    return {
        getInputBindings,
        getFieldBindings,
        getLabelBindings,
        getDefaultValue,
        defaultOption,
    };
};

const getId = (handles) => {
    if (handles && (typeof handles === 'object' || Array.isArray(handles))) {
        return handles.join('-');
    }

    return handles;
};

const getName = (handles) => {
    if (handles && (typeof handles === 'object' || Array.isArray(handles))) {
        return `${handles.join('.')}`;
    }

    return handles;
};

// Position can be something like: verbb\\formie\\positions\\Hidden
const getLabelPosition = (position) => {
    if (!position) {
        return '';
    }

    const parts = position.split('\\');
    return parts[parts.length - 1].toLowerCase();
};

export const attributesTable = (table) => {
    const attrs = {};

    if (table && Array.isArray(table)) {
        table.forEach((row) => {
            attrs[row.label] = row.value;
        });
    }

    return attrs;
};
