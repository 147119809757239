import { default as _91_46_46_46slug_93mmnngZ98qDMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93xWZmHQNxM3Meta } from "/builds/allard-pierson/allard-pierson-frontend/pages/agenda/[...slug].vue?macro=true";
import { default as archiefdXwInRjkSEMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/agenda/archief.vue?macro=true";
import { default as indexjmqDPJwG8zMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/agenda/index.vue?macro=true";
import { default as _91_46_46_46slug_93ZVArPSZPDJMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/blog/[...slug].vue?macro=true";
import { default as indexhCT6YiixwdMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93vDM8mK2TjwMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/collectie/[...slug].vue?macro=true";
import { default as indexhxc3vhSj35Meta } from "/builds/allard-pierson/allard-pierson-frontend/pages/collectie/index.vue?macro=true";
import { default as indexG3eYmZuWI1Meta } from "/builds/allard-pierson/allard-pierson-frontend/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93annklYdurvMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/nieuws/[...slug].vue?macro=true";
import { default as indexmxxdLDErMhMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/nieuws/index.vue?macro=true";
import { default as zoekuOU6xhhoHiMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/zoek.vue?macro=true";
import { default as component_45stub6nnEZYBukgMeta } from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub6nnEZYBukg } from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_less@4.2_j6if5qsypxh7gozycs7fx7rcq4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/[...slug].vue")
  },
  {
    name: "agenda-slug___nl",
    path: "/agenda/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/agenda/[...slug].vue")
  },
  {
    name: "agenda-slug___en",
    path: "/en/calendar/:calendar()",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/agenda/[...slug].vue")
  },
  {
    name: "agenda-archief___nl",
    path: "/agenda/archief",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/agenda/archief.vue")
  },
  {
    name: "agenda-archief___en",
    path: "/en/calendar/archive",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/agenda/archief.vue")
  },
  {
    name: "agenda___nl",
    path: "/agenda",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/agenda/index.vue")
  },
  {
    name: "agenda___en",
    path: "/en/calendar",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/agenda/index.vue")
  },
  {
    name: "blog-slug___nl",
    path: "/blog/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:blog()",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/blog/[...slug].vue")
  },
  {
    name: "blog___nl",
    path: "/blog",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/blog/index.vue")
  },
  {
    name: "collectie-slug___nl",
    path: "/collectie/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/collectie/[...slug].vue")
  },
  {
    name: "collectie-slug___en",
    path: "/en/collection/:collection()",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/collectie/[...slug].vue")
  },
  {
    name: "collectie___nl",
    path: "/collectie",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/collectie/index.vue")
  },
  {
    name: "collectie___en",
    path: "/en/collection",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/collectie/index.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/index.vue")
  },
  {
    name: "nieuws-slug___nl",
    path: "/nieuws/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/nieuws/[...slug].vue")
  },
  {
    name: "nieuws-slug___en",
    path: "/en/news/:news()",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/nieuws/[...slug].vue")
  },
  {
    name: "nieuws___nl",
    path: "/nieuws",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/nieuws/index.vue")
  },
  {
    name: "nieuws___en",
    path: "/en/news",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/nieuws/index.vue")
  },
  {
    name: "zoek___nl",
    path: "/zoek",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/zoek.vue")
  },
  {
    name: "zoek___en",
    path: "/en/search",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/zoek.vue")
  },
  {
    name: component_45stub6nnEZYBukgMeta?.name,
    path: "/vrienden",
    component: component_45stub6nnEZYBukg
  },
  {
    name: component_45stub6nnEZYBukgMeta?.name,
    path: "/schenken",
    component: component_45stub6nnEZYBukg
  },
  {
    name: component_45stub6nnEZYBukgMeta?.name,
    path: "/steun",
    component: component_45stub6nnEZYBukg
  },
  {
    name: component_45stub6nnEZYBukgMeta?.name,
    path: "/events/herkomstonderzoek-naar-illegale-handel-het-reconstrueren-van-netwerken-rond-archeologische-objecten",
    component: component_45stub6nnEZYBukg
  },
  {
    name: component_45stub6nnEZYBukgMeta?.name,
    path: "/en/events/fellow-lecture-provenance-research-on-illicit-trade",
    component: component_45stub6nnEZYBukg
  },
  {
    name: component_45stub6nnEZYBukgMeta?.name,
    path: "/bezoek/allard-pierson-live/de-studio",
    component: component_45stub6nnEZYBukg
  },
  {
    name: component_45stub6nnEZYBukgMeta?.name,
    path: "/agenda/opening-amsterdam-eet",
    component: component_45stub6nnEZYBukg
  }
]