export const useFormStore = defineStore('form-store', {
    state: () => {
        return {
            forms: {},
            locale: 'en',
        };
    },
    getters: {
        getForm(state) {
            return handle => state.forms[handle];
        },
        progress() {
            return (handle) => {
                const form = this.getForm(handle);
                if (!form || !form.pages) {
                    return 0;
                }

                return form.pages
                    ? parseInt(
                        ((form.activePage + 1) / form.pages.length) * 100,
                        10
                    )
                    : 0;
            };
        },
        getFormMeta() {
            return (form) => {
                if (!form) {
                    return {};
                }

                return form?.pages.flatMap(({ rows }) => rows.flatMap(row => row.fields)) || [];
            };
        },
    },
    actions: {
        setForm(form, locale) {
            if (!form) {
                return;
            }

            this.locale = locale;
            this.forms[form.handle] = {
                data: this.initializeData(form),
                activePage: 0,
                loading: false,
                error: false,
                submitted: false,
                errorList: [],
                message: '',
                ...form,
            };

            return this.forms[form.handle];
        },
        initializeData(form) {
            if (!form || !form.pages) {
                return {};
            }

            return this.getFormMeta(form).reduce((obj, field) => {
                obj[field.handle] = getField(field).getDefaultValue();
                return obj;
            }, {});
        },
        setActivePage(handle, page) {
            const form = this.getForm(handle);
            form.activePage = page;
        },
        setFormErrors(handle, errors) {
            const form = this.getForm(handle);
            form.errorList = errors;
        }
    },
});
