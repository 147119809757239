import DefaultFormField from './components/base/BaseFormField.vue';
import DefaultInput from './components/base/BaseInput.vue';
import DefaultTextarea from './components/base/BaseTextarea.vue';
import DefaultRadio from './components/base/BaseRadio.vue';
import DefaultSelect from './components/base/BaseSelect.vue';
import DefaultCheckbox from './components/base/BaseCheckbox.vue';
import DefaultButton from './components/base/BaseButton.vue';
import DefaultFile from './components/base/BaseFile.vue';

export default class FormiePlugin {
    #baseComponents = {
        formField: DefaultFormField,
        input: DefaultInput,
        textarea: DefaultTextarea,
        radio: DefaultRadio,
        select: DefaultSelect,
        checkbox: DefaultCheckbox,
        button: DefaultButton,
        file: DefaultFile,
    };

    #baseFields = {
        InputSingleLineText: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputSection: defineAsyncComponent(() => import('./components/inputs/InputSection.vue')),
        InputRadio: defineAsyncComponent(() => import('./components/inputs/InputRadio.vue')),
        InputPhone: defineAsyncComponent(() => import('./components/inputs/InputPhone.vue')),
        InputNumber: defineAsyncComponent(() => import('./components/inputs/InputNumber.vue')),
        InputName: defineAsyncComponent(() => import('./components/inputs/InputName.vue')),
        InputMultiLineText: defineAsyncComponent(() => import('./components/inputs/InputMultiLineText.vue')),
        InputHtml: defineAsyncComponent(() => import('./components/inputs/InputHtml.vue')),
        InputHidden: defineAsyncComponent(() => import('./components/inputs/InputHidden.vue')),
        InputHeading: defineAsyncComponent(() => import('./components/inputs/InputHeading.vue')),
        InputFileUpload: defineAsyncComponent(() => import('./components/inputs/InputFileUpload.vue')),
        InputEmail: defineAsyncComponent(() => import('./components/inputs/InputEmail.vue')),
        InputDropdown: defineAsyncComponent(() => import('./components/inputs/InputDropdown.vue')),
        InputDate: defineAsyncComponent(() => import('./components/inputs/InputDate.vue')),
        InputCheckboxes: defineAsyncComponent(() => import('./components/inputs/InputCheckboxes.vue')),
        InputAgree: defineAsyncComponent(() => import('./components/inputs/InputAgree.vue')),
        InputAddress: defineAsyncComponent(() => import('./components/inputs/InputAddress.vue')),
        InputNamePrefix: defineAsyncComponent(() => import('./components/inputs/InputNamePrefix.vue')),
        InputNameFirst: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputNameMiddle: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputNameLast: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputAddress1: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputAddress2: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputAddress3: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputAddressCity: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputAddressZip: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputAddressState: defineAsyncComponent(() => import('./components/inputs/InputSingleLineText.vue')),
        InputAddressCountry: defineAsyncComponent(() => import('./components/inputs/InputDropdown.vue')),
    };

    get components() {
        return this.#baseComponents;
    }

    get fields() {
        return this.#baseFields;
    }

    getComponent(name) {
        return this.#baseComponents[name];
    }

    getField(name) {
        return this.#baseFields[name];
    }

    constructor(_, config = {}) {
        // Registering base components for Formie
        this.#baseComponents = {
            ...this.#baseComponents,
            // Custom components from parent project to replace base default components
            ...config.components ?? {}
        };

        this.#baseFields = {
            ...this.#baseFields,
            // Custom components from parent project to replace base default components
            ...config.fields ?? {}
        };
    }
}
