// Will be @digitalnatives/formie
import FormiePlugin from '../formie-plugin/index.js';

export default defineNuxtPlugin({
    name: 'dn-formie',
    parallel: true,
    setup(nuxtApp) {
        nuxtApp.vueApp.use(FormiePlugin);
    }
});
