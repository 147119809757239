// Default error messages per language
const messages = {
    en: {
        email: 'Please enter a valid email address',
        required: 'This field is required',
    },
    nl: {
        email: 'Vul een geldig e-mailadres in',
        required: 'Dit veld is verplicht',
    },
};

export const validationMessages = (field, requiredErrorMessage = '') => {
    // Check whether a custom error message is set from any of the subfields.
    requiredErrorMessage = requiredErrorMessage || field.errorMessage;
    const attrs = {};

    // Get the locale messages based on the current locale
    const localeMessages = messages[useFormStore().locale];

    // Set the error messages for the field
    if (field.required) {
        attrs['data-pristine-required-message'] =
            requiredErrorMessage || localeMessages.required;
    }

    if (field.handle === 'email') {
        attrs['data-pristine-email-message'] = localeMessages.email;
    }

    return attrs;
};
